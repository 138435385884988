<template>
    <div>
        <div id="not-found-page">
            <div id="status-code">
                404
            </div>
            <h1>Page not found</h1>
            <div id="error-message">
                We're sorry, the page you requested could not be found. Please go back to the homepage or contact support for further assistance.
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#not-found-page {
    max-width: 500px;
    margin: auto;
    padding-top: 300px;
}
#status-code {
  font-size: 100px;
}
</style>
